import type { LANGType } from '@src/types/shared/lang.schema'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import { ProfileSpaceLayout } from '@components/spaces/profileSpaceLayout'
import { ProfileSpaceView } from '@components/spaces/ProfileSpaceView'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import { LandingPageLoadingShimmer } from '@components/ui/loaders/LandingPageLoadingShimmer'
import { useSwiper } from '@components/ui/swipper/useSwiper'
import { useSite } from '@contexts/site/site.context'
import { useIs } from '@src/hooks/useIs'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { typesenseService } from '@src/services/typesense/typesense.service'
import { sodiumConfig } from '@src/sodiumConfig'
import { ArrowRight, PencilIcon } from 'lucide-react'

export const Space: FC = () => {
  const siteContext = useSite()
  const { isSpacePage } = useIs()
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const slug = String(router.query?.space)

  const { data: account, isLoading } =
    typesenseService.useGetMainPageBySlug(slug)

  const { user } = useAuth()
  const [isAccountOwner, setIsAccountOwner] = useState(false)
  const accountId = account?.id

  const hasLandingPage = account?.landingPageHTML

  useEffect(() => {
    setIsAccountOwner(user?.account?.id === accountId)
  }, [accountId, user])

  useSwiper(
    '.swiper',
    {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    [siteContext.accountId, account?.landingPageHTML],
  )

  const handleLanguage = (language: LANGType) => {
    if (language === 'en_us') {
      return 'English - US'
    }
    return 'Español - CL'
  }

  const handleEditProfile = async () => {
    return await router.push(routes.dynamic.ACCOUNT_SETTINGS())
  }

  if (!siteContext.accountId && !isSpacePage) {
    return <LandingPageLoadingShimmer />
  }

  if (isSpacePage && !account && isLoading) {
    return <LandingPageLoadingShimmer />
  }

  // console.log({ account })
  // if (!account) {
  //   return <></>
  // }

  if (hasLandingPage) {
    return (
      <ProfileSpaceLayout>
        <style
          dangerouslySetInnerHTML={{
            __html: siteContext.isSpace ? siteContext.landingPageCSS : '',
          }}
        ></style>
        <RichTextDisplay content={account?.landingPageHTML || ''} />
      </ProfileSpaceLayout>
    )
  }

  return (
    <ProfileSpaceLayout>
      {/* <style
        dangerouslySetInnerHTML={{
          __html: siteContext.isSpace
            ? siteContext.landingPageCSS
            : data?.landingPageCSS || '',
        }}></style>
      <RichTextDisplay
        content={
          siteContext.isSpace
            ? siteContext.landingPageHTML
            : data?.landingPageHTML || ''
        }
      /> */}

      <div className="mt-5 grid grid-cols-12 gap-4 pb-10 pt-2 md:p-6 md:max-[1060px]:w-10/12 md:max-[768px]:mx-auto">
        {isAccountOwner && (
          <div className="col-[10_/_span_2] row-[1_/_span_2] h-fit w-[300px] rounded-md max-[768px]:col-span-12 max-[768px]:row-[6_/_span_1] max-[768px]:w-full lg:col-[9_/_span_1]">
            <div className="divide-y rounded-md bg-white dark:bg-[rgb(29,34,38)]">
              <div className="flex justify-between">
                <div className="flex-col p-4 pr-2 dark:text-white">
                  <span className="flex pr-2 font-bold dark:text-white">
                    Profile language
                  </span>
                  <span className="flex text-[14px] text-gray-400">
                    {handleLanguage(user?.account?.language || 'en_us')}
                  </span>
                </div>
                <PencilIcon
                  onClick={handleEditProfile}
                  className="mr-4 mt-4 h-5 w-5 cursor-pointer text-gray-400"
                />
              </div>

              <div className="flex justify-between">
                <div className="flex-col p-4 pr-2 dark:text-white">
                  <span className="flex pr-2 font-bold">
                    Public profile & URL
                  </span>
                  <span className="flex text-[14px] text-gray-400">
                    {user?.account?.slug}
                  </span>
                </div>
                <PencilIcon
                  onClick={handleEditProfile}
                  className="mr-4 mt-4 h-5 w-5 cursor-pointer text-gray-400"
                />
              </div>
            </div>
          </div>
        )}

        <ProfileSpaceView account={account} accountId={String(accountId)} />

        {isAccountOwner && sodiumConfig.isMarketPlace && (
          <>
            <div className="col-[1_/_span_12] row-[3_/_span_1] flex items-center justify-between rounded-md border-solid bg-white dark:bg-[rgb(29,34,38)] max-[768px]:col-span-12 md:col-[1_/_span_9] lg:col-[2_/_span_7]">
              <p className="ml-5">
                <div className="flex-col pt-3">
                  <div>
                    <span className="font-bold dark:text-white">
                      Analytics & tools
                    </span>
                  </div>
                  <div>
                    <span className="pr-1 text-[12px] font-bold text-gray-400">
                      Post impressions past 7 days
                    </span>
                    <span className="text-[12px] font-bold dark:text-white">
                      197
                    </span>
                    <span className="ml-1 text-[12px] font-bold text-green-400">
                      <i className="fa-solid fa-caret-up"></i>19,600%
                    </span>
                  </div>
                </div>
              </p>
              <ArrowRight className="mr-5 text-gray-400" />
            </div>
            <div className="col-[1_/_span_12] row-[4_/_span_1] flex items-center justify-between rounded-md border-solid bg-white dark:bg-[rgb(29,34,38)] max-[768px]:col-span-12 md:col-[1_/_span_9] lg:col-[2_/_span_7]">
              <p className="ml-5">
                <div className="flex-col pt-3">
                  <div>
                    <span className="font-bold dark:text-white">About</span>
                  </div>
                  <div className="pt-3">
                    <RichTextDisplay content={user?.account?.about} />
                  </div>
                </div>
              </p>
            </div>
          </>
        )}
      </div>
    </ProfileSpaceLayout>
  )
}

export default Space
